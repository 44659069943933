/* system fonts */
body {
  font-family: Georgia, serif;
  font-size: 18px;
}

/* web fonts */
.gza-ready body {
  font-family: 'GZA', serif;
}
